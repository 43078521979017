import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "deleteButton", "card" ]

  connect() {
    this.deleteButtonTarget.addEventListener('ajax:beforeSend', (e) => this.startDelete(e))
    this.deleteButtonTarget.addEventListener('ajax:success', (e) => this.successDelete(e))
    this.deleteButtonTarget.addEventListener('ajax:error', (e) => this.errorDelete(e))
    this.element.addEventListener('transitionend', (e) => this.removeAnimationEnded(e))
  }

  startDelete(ev) {
    this.element.classList.toggle('show', false)
  }

  successDelete(ev) {
    if (ev.detail[0].success == true) {
      // Permanently delete
      if (this.element.dataset.animation_ended) {
        this.element.remove()
      } else {
        this.element.dataset.delete_succeeded = true
      }
    } else {
      this.element.dataset.delete_succeeded = false
      alert('Could not delete photo!')
    }
  }

  removeAnimationEnded(ev) {
    if (ev.target != this.element) {
      return
    }
    this.element.dataset.animation_ended = true
    if (this.element.dataset.delete_succeeded) {
      this.element.remove()
    }
  }

  errorDelete(ev) {
    this.element.classList.toggle('show', true)
    this.cardTarget.classList.toggle('border-danger', true)
    this.element.dataset.delete_succeeded = false
    alert('Could not delete photo!')
  }
}
