/* eslint no-console:0 */
// Entry point for the build script in your package.json

import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import * as bootstrap from "bootstrap"

import Rails from "@rails/ujs"
Rails.start()

import * as ActiveStorage from "activestorage"
ActiveStorage.start()

import "./controllers";

document.addEventListener("DOMContentLoaded", function(event) {
    jQuery('[data-toggle="tooltip"]').tooltip()
});