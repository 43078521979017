import { Controller } from "@hotwired/stimulus"

function contains(list, value) {
    for( var i = 0; i < list.length; ++i ) {
        if(list[i] === value) return true;
    }
    return false;
}

export default class extends Controller {
  static targets = ["cardTemplate", "cardCollection"]

  connect() {
    this.element.addEventListener('drop', (e) => this.drop(e))
    this.element.addEventListener('dragenter', (e) => this.dragenter(e))
    this.element.addEventListener('dragover', (e) => this.dragover(e))
    this.element.addEventListener('dragleave', (e) => this.dragleave(e))
    this.new_elements_counter = 0
  }

  dragenter(ev) {
    if (!contains(ev.dataTransfer.types, 'Files')) {
      ev.preventDefault();
      return;
    }
    this.element.classList.toggle('dragover', true)
    ev.preventDefault();
  }

  dragover(ev) {
    if (!contains(ev.dataTransfer.types, 'Files')) {
      ev.preventDefault();
      return;
    }
    this.element.classList.toggle('dragover', true)
    ev.preventDefault();
  }

  dragleave(ev) {
    this.element.classList.toggle('dragover', false)
  }

  drop(event) {
    if (!contains(event.dataTransfer.types, 'Files')) {
      return;
    }
    this.element.classList.toggle('dragover', false)
    event.preventDefault();

    const files = event.dataTransfer.files;
    Array.from(files).forEach(file => this.createCard(file))
  }

  createCard(file) {
    const card = this.cardTemplateTarget.querySelector('*').cloneNode(true)

    this.new_elements_counter++
    const counter = Date.now() * 1000 + this.new_elements_counter
    card.querySelectorAll('[name]').forEach( named_input => {
      named_input.removeAttribute('disabled')
      named_input.setAttribute('name',
                               named_input.getAttribute('name').
                                 replace('[-1]', '[' + counter + ']'))
    })

    card.querySelectorAll('[id]').forEach( named_input => {
      named_input.setAttribute('id',
                               named_input.getAttribute('id').
                                 replace('_-1_', '[' + counter + ']'))
    })

    card.querySelectorAll('[for]').forEach( named_input => {
      named_input.setAttribute('for',
                               named_input.getAttribute('for').
                                 replace('_-1_', '[' + counter + ']'))
    })
    card.uploadedFile = file

    this.cardCollectionTarget.append(card)
  }
}
