import { application } from "./application"

import PhotosAlbumPhotosController from "./photos_album_photos_controller";
application.register("photos-album-photos", PhotosAlbumPhotosController);

import PhotosAlbumSorter from "./photos_album_sorter_controller";
application.register("photos-album-sorter", PhotosAlbumSorter);

import PhotosPhotoController from "./photos_photo_controller";
application.register("photos-photo", PhotosPhotoController);

import PhotosPhotoUploadController from "./photos_photo_upload_controller";
application.register("photos-photo-upload", PhotosPhotoUploadController);

import SlideshowController from "./slideshow_controller";
application.register("slideshow", SlideshowController);