import { Controller } from "@hotwired/stimulus"

import sortable from "html5sortable/dist/html5sortable.es.js";

export default class extends Controller {
  static targets = [ "photo" ]

  connect() {
    const sorter = sortable(
      this.element,
      {
        forcePlaceholderSize: true,
        placeholderClass: 'col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 sortable-placeholder',
      }
    )[0]

    sorter.addEventListener('sortupdate', (e) => this.sortUpdate(e))
  }

  sortUpdate(e) {
    const moved_item = e.detail.item
    const new_index = e.detail.destination.index
    const old_index = e.detail.destination.itemsBeforeUpdate.indexOf(moved_item)

    var taken_place_of
    if (old_index < new_index) {
      taken_place_of = e.detail.destination.items[new_index - 1]
    } else {
      taken_place_of = e.detail.destination.items[new_index + 1]
    }

    const request = new XMLHttpRequest();
    request.open('POST',  e.detail.item.dataset.updateUrl, true);
    request.addEventListener('load', () => {
      if (request.status >= 200 && request.status < 400) {
        this.updateSequenceNumbers(JSON.parse(request.responseText))
      } else {
        // We reached our target server, but it returned an error
      }
    })
    request.setRequestHeader('X-CSRF-Token', document.querySelector("meta[name=csrf-token]").getAttribute("content"))
    const request_data = new FormData()
    request_data.append('_method', 'PATCH')
    request_data.append('photos_photo[sequence_number]', taken_place_of.dataset.sequenceNumber)
    request.send(request_data)
  }

  updateCover(event) {
    const request = new XMLHttpRequest();
    request.open('POST', this.element.dataset.updateAlbumUrl, true);
    request.addEventListener('load', () => {
      if (request.status >= 200 && request.status < 400) {
        this.updateCoverIndications(JSON.parse(request.responseText))
      } else {
        // We reached our target server, but it returned an error
      }
    })
    request.setRequestHeader('X-CSRF-Token', document.querySelector("meta[name=csrf-token]").getAttribute("content"))
    const request_data = new FormData()
    request_data.append('_method', 'PATCH')
    
    var photoId = undefined
    var current_node = event.target
    while (photoId == undefined) {
      photoId = current_node.dataset.photoId
      current_node = current_node.parentNode
    }
    request_data.append('photos_album[cover_photo_id]', photoId)
    request.send(request_data)
    event.preventDefault()
  }

  updateCoverIndications(e) {
    for (var i = 0; i < this.photoTargets.length; i++) {
      const photo = this.photoTargets[i]
      photo.classList.toggle('is-album-cover', e.cover_photo_id == photo.dataset.photoId)
    }
  }

  updateSequenceNumbers(e) {
    for (var i = 0; i < this.photoTargets.length; i++) {
      const photo = this.photoTargets[i]
      photo.dataset.sequenceNumber = e.photos[photo.dataset.photoId].sequence_number
    }
  }
}
