import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "activestorage"

class UploadHandler {
  constructor(progress_bar) {
    this.progress_bar = progress_bar
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress",
      event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    this.progress_bar.style.width = (event.loaded / event.total) * 100 + '%'
  }
}

export default class extends Controller {
  static targets = ["previewImg", "progressBar", "progress", "fileHiddenInput"]

  connect() {
    const file = this.element.uploadedFile
    if (file == undefined) {
      console.log('this must be template, bailing out')
      return true
    }

    this.progressTarget.classList.toggle('d-none', false)

    var reader = new FileReader()
    reader.onload = (e) => this.previewImgTarget.setAttribute('src', e.target.result)
    reader.readAsDataURL(file)

    const url = this.element.querySelector('[data-direct-upload-url]').dataset.directUploadUrl

    const upload = new DirectUpload(file, url, new UploadHandler(this.progressBarTarget))
    upload.create((error, blob) => {
      this.progressTarget.classList.toggle('d-none', true)
      if (error) {
        console.log('ARE WE ERRORING OUT?')
        console.log(error)
      } else {
        // Add an appropriately-named hidden input to the form with a
        //  value of blob.signed_id so that the blob ids will be
        //  transmitted in the normal upload flow
        this.fileHiddenInputTarget.setAttribute('value', blob.signed_id)
      }
    })
  }

  remove(event) {
    this.element.remove()
    event.preventDefault()
  }
}
