import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'img', 'fullsizeImg', 'fullsizeNextImg',
                     'fullscreenButton', 'fullscreenCloseButton',
                     'highlight',
                     'currentTitle',
                     'currentDescription',
                     'loadingIndication',
                     'navigationContainer',
                     'photoDetails', 'currentPhotoDetails' ]

  connect() {
    this.element.dataset.slideshowIndex = 0;
    window.addEventListener('popstate', (event) => this.popstate(event));

    if (this.element.requestFullscreen) {
      this.fullscreenButtonTarget.classList.toggle('d-none', false)
      document.addEventListener('fullscreenchange', (e) => this.fullscreenChange(e) )
    }

    this.fullsizeImgTarget.addEventListener('load', (e) => this.imageStopsLoading(e))
  }

  imageStopsLoading(event) {
    if (this.image_loading) {
      clearTimeout(this.image_loading)
      this.image_loading = null
    }
    this.loadingIndicationTarget.classList.toggle('d-none', true)
  }

  popstate(event) {
    if (event.state == null || event.state.open == false) {
      this.highlightTarget.classList.toggle('open', false)
      document.body.classList.toggle('slideshow-open', false)

      if (document.fullscreenElement !== null) {
        document.exitFullscreen();
      }
    } else if (event.state.open == true) {
      this.highlightTarget.classList.toggle('open', true)
      document.body.classList.toggle('slideshow-open', true)
      this.navigationContainerTarget.classList.toggle('start-playing', true)
      this.showCurrent()
    }
  }

  openAndContinue(event) {
    history.pushState({ open: true }, "")
    this.highlightTarget.classList.toggle('open', true)
    document.body.classList.toggle('slideshow-open', true)
    this.navigationContainerTarget.classList.toggle('start-playing', true)
    this.showCurrent()
    event.preventDefault()
  }

  start(event) {
    history.pushState({ open: true }, "")
    this.element.dataset.slideshowIndex = this.imgTargets.indexOf(event.target)
    this.highlightTarget.classList.toggle('open', true)
    document.body.classList.toggle('slideshow-open', true)
    this.navigationContainerTarget.classList.toggle('start-playing', true)
    this.showCurrent()
    event.preventDefault()
  }

  close(event) {
    history.pushState({ open: false }, "")
    this.highlightTarget.classList.toggle('open', false)
    document.body.classList.toggle('slideshow-open', false)

    if (document.fullscreenElement !== null) {
      document.exitFullscreen();
    }

    event.preventDefault()
  }

  next(event) {
    this.element.dataset.slideshowIndex++
    if (this.index >= this.imgTargets.length) {
      this.element.dataset.slideshowIndex = 0
    }
    this.showCurrent()
    event.preventDefault()
  }

  previous(event) {
    this.element.dataset.slideshowIndex--
    if (this.index < 0) {
      this.element.dataset.slideshowIndex = this.imgTargets.length - 1
    }
    this.showCurrent()
    event.preventDefault()
  }

  fullscreen(event) {
    if (document.fullscreenElement !== null) {
      document.exitFullscreen();
    } else {
      this.element.requestFullscreen();
    }
    event.preventDefault()
  }

  clickNext(event) {
    if (event.target == this.highlightTarget || event.target == this.fullsizeImgTarget) {
      this.next(event);
    }
  }

  showCurrent() {
    this.fullsizeImgTarget.src = this.imgTargets[this.index].dataset.fullImageUrl

    const startLoadingIndicator = () => {
      this.image_loading = null
      if (this.fullsizeImgTarget.complete) {
        return true;
      }
      this.loadingIndicationTarget.classList.toggle('d-none', false)
    }
    setTimeout(startLoadingIndicator, 100)

    this.fullsizeNextImgTarget.src = this.imgTargets[this.next_index].dataset.fullImageUrl
    this.currentTitleTarget.textContent = this.imgTargets[this.index].dataset.title
    this.currentDescriptionTarget.textContent = this.imgTargets[this.index].dataset.description
    const photo_details = this.photoDetailsTargets[this.index].cloneNode(true)
    photo_details.classList.toggle('d-none', false)
    this.currentPhotoDetailsTarget.removeChild(this.currentPhotoDetailsTarget.firstChild)
    this.currentPhotoDetailsTarget.append(photo_details)
  }

  fullscreenChange() {
    if (document.fullscreenElement !== null) {
      this.fullscreenButtonTarget.classList.toggle('d-none', true)
      this.fullscreenCloseButtonTarget.classList.toggle('d-none', false)
    } else {
      this.fullscreenButtonTarget.classList.toggle('d-none', false)
      this.fullscreenCloseButtonTarget.classList.toggle('d-none', true)
    }
  }

  toggleRevealItem(event) {
    event.currentTarget.classList.toggle('closed');
  }

  get index() {
    return parseInt(this.element.dataset.slideshowIndex)
  }

  get next_index() {
    if (this.index == this.imgTargets.length - 1) {
      return 0
    } else {
      return this.index + 1
    }
  }
}
